import Vue from "vue";
import router from "../routes";

export const store = Vue.observable({
  toast: null,
  users: [],
  phones: [],
  departments: [
    {
      id: 1,
      name: "IT",
    },
    {
      id: 2,
      name: "Sales",
    },
    {
      id: 3,
      name: "Dealing",
    },
    {
      id: 4,
      name: "Back office",
      color: "#3A5311",
    },
    {
      id: 5,
      name: "Compliance",
    },
    {
      id: 6,
      name: "Marketing",
    },
    {
      id: 7,
      name: "HR",
    },
    {
      id: 8,
      name: "Management",
    },
    {
      id: 9,
      name: "Accounting",
    },
  ],
  branches: [
    {
      name: "ALL",
    },
    {
      name: "EDR",
    },
    {
      name: "BENOR",
    },
    {
      name: "EDR/BENOR",
    },
  ],
  // phoneLines: [
  //   {
  //     country: "Cyprus",
  //     phones: [
  //       {
  //         branch: "EDR",
  //         line: "+35725030671",
  //       },
  //       {
  //         branch: "EDR",
  //         line: "+35725030685",
  //       },
  //       {
  //         branch: "EDR",
  //         line: "+35722222556",
  //       },
  //       {
  //         branch: "EDR",
  //         line: "+35722222557",
  //       },
  //       {
  //         branch: "EDR",
  //         line: "+35722222554",
  //       },
  //       {
  //         branch: "EDR",
  //         line: "+35722222560",
  //       },
  //       {
  //         branch: "EDR",
  //         line: "+35722222558",
  //       },
  //     ],
  //   },
  //   {
  //     country: "UK",
  //     phones: [
  //       {
  //         branch: "EDR",
  //         line: "+442037693646",
  //       },
  //       {
  //         branch: "BENOR",
  //         line: "+442037693649",
  //       },
  //     ],
  //   },
  //   {
  //     country: "France",
  //     phones: [
  //       {
  //         branch: "EDR",
  //         line: "+33375773995",
  //       },
  //       {
  //         branch: "BENOR",
  //         line: "+33375773752",
  //       },
  //     ],
  //   },
  //   {
  //     country: "Germany",
  //     phones: [
  //       {
  //         branch: "EDR",
  //         line: "+4961718913050",
  //       },
  //       {
  //         branch: "BENOR",
  //         line: "+4961718913051",
  //       },
  //     ],
  //   },
  //   {
  //     country: "Italy",
  //     phones: [
  //       {
  //         branch: "EDR",
  //         line: "+3903321320001",
  //       },
  //       {
  //         branch: "BENOR",
  //         line: "+3903321320002",
  //       },
  //     ],
  //   },
  //   {
  //     country: "Israel",
  //     phones: [
  //       {
  //         branch: "EDR/BENOR",
  //         line: "+0733632082",
  //       },

  //       {
  //         branch: "EDR/BENOR",
  //         line: "+0733632083",
  //       },
  //     ],
  //   },
  // ],
  phoneLines: [
    "35722222737",
    "35725030671",
    "35722222737",
    "35722222560",
    "35722222554",
    "35722222556",
    "35722222557",
    "35722222737",
    "442037693649",
  ],
  userLoggedIn: {},
  selectedUser: {},
  selectedPhone: {},
  selectedTask: {},
  response: {
    success: "",
    message: "",
  },
  totalRows: 0,
  tasks: [],
  statuses: [
    {
      id: 1,
      name: "TODO",
    },
    {
      id: 2,
      name: "DOING",
    },
    {
      id: 3,
      name: "DONE",
    },
  ],
  priorities: {
    0: {
      name: "High",
      class: "badge bg-danger rounded-pill",
    },
    1: {
      name: "Medium",
      class: "badge bg-primary rounded-pill",
    },
    2: {
      name: "Low",
      class: "badge bg-secondary rounded-pill",
    },
  },
  posts: [],
  publicHolidays: [],
});

export const getters = {
  userLoggedIn() {
    return store.userLoggedIn;
  },
  users() {
    return store.users;
  },
  selectedUser() {
    return store.selectedUser;
  },
  departments() {
    return store.departments;
  },
  branches() {
    return store.branches;
  },
  phones() {
    return store.phones;
  },
  phoneLines() {
    return store.phoneLines;
  },
  selectedPhone() {
    return store.selectedPhone;
  },
  toast() {
    return store.toast;
  },
  response() {
    return store.response;
  },
  isAdmin() {
    return store.userLoggedIn.isAdmin;
  },
  hasPermissions() {
    return store.userLoggedIn.isAdmin || store.userLoggedIn.role <= 2;
  },
  totalRows() {
    return store.totalRows;
  },
  tasks() {
    return store.tasks;
  },
  selectedTask() {
    return store.selectedTask;
  },
  statuses() {
    return store.statuses;
  },
  priorities() {
    return store.priorities;
  },
  posts() {
    return store.posts;
  },
  leaves() {
    return store.leaves;
  },
  leaveStatus() {
    return store.leaveStatus;
  },
  publicHolidays() {
    return store.publicHolidays;
  },
  departmentLeaves() {
    const { role, department } = store.userLoggedIn;
    return store.users.filter(({ name }) => {
      return role !== 1 ? name === department : [] && name !== "Management";
    });
  },
};

export const mutations = {
  setToast(toast) {
    store.toast = toast;
  },
  showToast({ success, message }) {
    store.response = {
      icon: success ? "fa-check-circle" : "fa-exclamation-circle",
      class: success ? "success" : "failed",
      success: success,
      message: message,
    };
    store.toast.show();
  },
  setLoggedInUser(user = {}) {
    store.userLoggedIn = user;
  },
  setLocalStorage(loggedInUser, token) {
    mutations.setLoggedInUser(loggedInUser);
    window.localStorage.setItem("token", token);
    window.localStorage.setItem("role", loggedInUser.role);
    window.localStorage.setItem("isAdmin", loggedInUser.isAdmin);
    window.localStorage.setItem("accessLevel", loggedInUser.accessLevel);
    router.push("/home");
  },
  logoutUser() {
    window.localStorage.clear();
    mutations.setLoggedInUser();
    router.push("/login").catch(() => {});
  },
  createDepartments(dataList) {
    let rows = [];
    store.totalRows = 0;
    const groupByDepartment = dataList.reduce((group, product) => {
      const { department } = product;
      group[department] = group[department] ?? [];
      group[department].push(product);
      store.totalRows++;
      return group;
    }, {});
    Object.entries(groupByDepartment).map((item) => {
      rows.push({
        name: item[0],
        children: item[1],
      });
      store.totalRows++;
    });
    return rows;
  },
  groupUserLeaves() {
    let rows = [];
    const groupByName = store.leaves.reduce((group, leave) => {
      const { title } = leave;
      group[title] = group[title] ?? [];
      group[title].push(leave);
      return group;
    }, {});
    Object.entries(groupByName).map((item) => {
      rows.push({
        name: item[0],
        children: item[1],
      });
    });
    return rows;
  },
  findDepartment(list, department) {
    if (list.length > 0) {
      return list.findIndex((el) => {
        return el.name === department;
      });
    }
    return [];
  },
  findIndex(list, id) {
    return list.findIndex(({ _id }) => {
      return _id === id;
    });
  },
  checkUser() {
    return (
      Object.keys(store.userLoggedIn).length === 0 &&
      store.userLoggedIn.constructor === Object
    );
  },
  setUsers(users) {
    store.users = users;
  },
  selectUser(user = {}) {
    store.selectedUser = {
      ...user,
    };
  },
  setUser(user) {
    let usersListIndex = mutations.findDepartment(store.users, user.department);
    if (usersListIndex > -1) {
      store.users[usersListIndex].children.push(user);
    } else {
      store.users.push({
        name: user.department,
        children: [{ ...user }],
      });
    }
  },
  updateUser(updatedUser) {
    store.users.map((department) => {
      let existingUserIndex = department.children.findIndex(
        (user) => user._id === updatedUser._id
      );
      if (existingUserIndex > -1) {
        let existingUser = department.children[existingUserIndex];
        if (existingUser.department === updatedUser.department) {
          if (updatedUser._id === store.userLoggedIn._id) {
            mutations.setLoggedInUser({ ...updatedUser });
          }
          department.children.splice(existingUserIndex, 1, updatedUser);
        } else {
          department.children.splice(existingUserIndex, 1);
          let usersListIndex = mutations.findDepartment(
            store.users,
            updatedUser.department
          );
          if (usersListIndex > -1) {
            let usersList = store.users[usersListIndex].children;
            usersList.push(updatedUser);
          }
        }
      }
    });
  },
  deleteUser(deletedUser) {
    let usersListIndex = mutations.findDepartment(
      store.users,
      deletedUser.department
    );
    if (usersListIndex > -1) {
      let usersList = store.users[usersListIndex].children;
      let userIndex = mutations.findIndex(usersList, deletedUser._id);
      if (userIndex > -1) {
        usersList.splice(userIndex, 1);
        if (usersList.length <= 0) {
          store.users.splice(usersListIndex, 1);
        }
      }
    }
  },
  updateUserRiskPercentage(risk) {
    store.userLoggedIn.risk = risk;
  },
  setPhones(phones) {
    store.phones = phones;
  },
  selectPhone(phone = {}) {
    store.selectedPhone = {
      ...phone,
    };
  },
  setPhone(phone) {
    store.phones.push(phone);
  },
  updatePhone(updatedPhone) {
    let phoneIndex = store.phones.findIndex(
      (phone) => phone._id === updatedPhone._id
    );
    if (phoneIndex > -1) {
      store.phones.splice(phoneIndex, 1, updatedPhone);
    }
  },
  deletePhone(deletedPhone) {
    let phoneIndex = store.phones.findIndex(
      (phone) => phone._id === deletedPhone._id
    );
    if (phoneIndex > -1) {
      store.phones.splice(phoneIndex, 1);
    }
  },
  convertDates(dates) {
    for (const property in dates) {
      dates[property] = mutations.convertDate(dates[property]);
    }
    return dates;
  },
  convertDate(date) {
    return new Date(date).toISOString().split("T")[0];
  },
  formatDates(dates) {
    for (const property in dates) {
      dates[property] = mutations.formatDate(dates[property]);
    }
    return dates;
  },
  formatDate(date) {
    return new Intl.DateTimeFormat("en-GB").format(new Date(date));
  },
  findDepartmentTask(department) {
    return store.tasks.findIndex(({ name }) => {
      return name === department;
    });
  },
  setTasks(tasks) {
    store.tasks = tasks;
  },
  // setTask(task) {
  //   let departmentIndex = mutations.findDepartmentTask(task.department);
  //   if (departmentIndex > -1) {
  //     store.tasks[departmentIndex].tasksList[task.status].push(task);
  //   } else {
  //     store.tasks.push({
  //       name: task.department,
  //       tasksList: {
  //         [task.status]: [{ ...task }],
  //       },
  //     });
  //     store.tasks.sort((a, b) => a.name.localeCompare(b.name));
  //   }
  // },
  // updateTask(updatedTask) {
  //   let departmentIndex = mutations.findDepartmentTask(updatedTask.department);
  //   if (departmentIndex > -1) {
  //     let tasksList = store.tasks[departmentIndex].tasksList;
  //     for (const key in tasksList) {
  //       let findTaskIndex = mutations.findIndex(
  //         tasksList[key],
  //         updatedTask._id
  //       );
  //       if (findTaskIndex > -1) {
  //         let status = tasksList[key][findTaskIndex].status;
  //         if (status === updatedTask.status) {
  //           tasksList[key].splice(findTaskIndex, 1, updatedTask);
  //         } else {
  //           if (tasksList[updatedTask.status]) {
  //             tasksList[updatedTask.status].push({ ...updatedTask });
  //           } else {
  //             Vue.set(tasksList, updatedTask.status, [{ ...updatedTask }]);
  //           }
  //           tasksList[key].splice(findTaskIndex, 1);
  //         }
  //       }
  //     }
  //   }
  // },
  // deleteTask(deletedTask) {
  //   let departmentIndex = mutations.findDepartmentTask(deletedTask.department);
  //   if (departmentIndex > -1) {
  //     let tasksList = store.tasks[departmentIndex].tasksList;
  //     for (const key in tasksList) {
  //       let findTaskIndex = mutations.findIndex(
  //         tasksList[key],
  //         deletedTask._id
  //       );
  //       if (findTaskIndex > -1) {
  //         tasksList[key].splice(findTaskIndex, 1);
  //       }
  //     }
  //   }
  // },
  setTask(task) {
    let departmentIndex = mutations.findDepartmentTask(task.department);
    if (departmentIndex > -1) {
      let taskType = store.tasks[departmentIndex].tasksList.find(({ name }) => {
        return name === task.status;
      });
      if (taskType) {
        taskType.items.push(task);
      }
    }
  },
  updateTask(updatedTask, isDraggable = false) {
    let departmentIndex = mutations.findDepartmentTask(updatedTask.department);
    if (departmentIndex > -1) {
      let tasksList = store.tasks[departmentIndex].tasksList;
      for (const key in tasksList) {
        let taskIndex = mutations.findIndex(
          tasksList[key].items,
          updatedTask._id
        );
        if (taskIndex > -1) {
          let selectedTask = tasksList[key].items[taskIndex];
          if (isDraggable) {
            let updatedTaskList = tasksList[updatedTask.updatedTaskListIndex];
            if (selectedTask.status !== updatedTaskList.name) {
              selectedTask.isDraggable = true;
              selectedTask.status = updatedTaskList.name;
              mutations.selectTask(selectedTask);
              updatedTaskList.items.push(selectedTask);
              tasksList[key].items.splice(taskIndex, 1);
              /* FOR FUTURE - If drag element as first re-arrange the array */
              // if (updatedTask.dropAsFirst) {
              //   updatedTaskList.items.unshift(selectedTask);
              // } else {
              //   updatedTaskList.items.push(selectedTask);
              // }
              // tasksList[key].items.splice(taskIndex, 1);
            }
          } else {
            if (selectedTask.status === updatedTask.status) {
              tasksList[key].items.splice(taskIndex, 1, updatedTask);
            } else {
              let updatedTaskListIndex = tasksList.findIndex(({ name }) => {
                return name === updatedTask.status;
              });
              if (updatedTaskListIndex > -1) {
                tasksList[key].items.splice(taskIndex, 1);
                tasksList[updatedTaskListIndex].items.push(updatedTask);
              }
            }
          }
        }
      }
    }
  },
  deleteTask(deletedTask) {
    let departmentIndex = mutations.findDepartmentTask(deletedTask.department);
    if (departmentIndex > -1) {
      let tasksList = store.tasks[departmentIndex].tasksList;
      let taskTypeList = tasksList.find(({ name }) => {
        return name === deletedTask.status;
      });
      if (taskTypeList) {
        let deletedTaskIndex = taskTypeList.items.findIndex(({ _id }) => {
          return _id === deletedTask._id;
        });
        if (deletedTaskIndex > -1) {
          taskTypeList.items.splice(deletedTaskIndex, 1);
        }
      }
    }
  },
  selectTask(task = {}) {
    store.selectedTask = { ...task };
  },
  setPosts(posts) {
    store.posts = posts;
  },
  setPost(post) {
    store.posts.push(post);
  },
  updatePost(post) {
    let postIndex = store.posts.findIndex(({ _id }) => {
      return _id === post._id;
    });
    if (postIndex > -1) {
      store.posts.splice(postIndex, 1, post);
    }
  },
  deletePost(selectedRows) {
    store.posts = store.posts.filter(
      (a) => !selectedRows.map((a) => a._id).includes(a._id)
    );
  },
  setLeaves(leaves) {
    store.leaves = leaves;
  },
  setLeave(leave) {
    store.leaves.push(leave);
  },
  setPublicHolidays(publicHolidays) {
    store.publicHolidays = publicHolidays;
  },
};
