import Vue from "vue";
import App from "./App.vue";
import router from "./routes";
import "./axios";
import "./validation";
import VueDraggable from "vue-draggable";
import VueGoodTablePlugin from "vue-good-table";
import { checkPermissions } from "./validation/permissions";

import "./assets/scss/main.scss";
import "bootstrap/dist/js/bootstrap.esm.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "vue-good-table/dist/vue-good-table.css";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(VueDraggable);
Vue.use(VueGoodTablePlugin);
Vue.config.productionTip = false;

Vue.directive("hasPermissions", (el, binding) => {
  const hasPermissions = checkPermissions(
    binding.value,
    binding.modifiers.isManager
  );
  if (hasPermissions) {
    return (el.hidden = false);
  } else {
    return (el.hidden = true);
  }
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
