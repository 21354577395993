<template>
  <div
    id="toast-alert"
    class="toast-container position-absolute p-3 top-0 end-0"
  >
    <div
      ref="toast"
      class="toast align-items-right"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div :class="['d-flex', response.class]">
        <div class="toast-body">
          <i :class="['fas', response.icon]"></i>
          {{ response.message }}
        </div>
        <button
          type="button"
          class="btn-close me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "bootstrap";
import { getters, mutations } from "../store";
export default {
  mounted() {
    mutations.setToast(new Toast(this.$refs.toast));
  },
  computed: {
    response() {
      return getters.response();
    },
  },
};
</script>