import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Dashboard",
      component: () =>
        import(/* webpackChunkName: "dashboard" */ "../views/dashboard.vue"),
      redirect: {
        name: "Home",
      },
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/home",
          name: "Home",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/home.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/users",
          name: "Users",
          component: () =>
            import(/* webpackChunkName: "users" */ "../views/users.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/phones",
          name: "Phones",
          component: () =>
            import(/* webpackChunkName: "phones" */ "../views/phones.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/logins",
          name: "Log (Logins)",
          component: () =>
            import(/* webpackChunkName: "logins" */ "../views/logins.vue"),
          meta: {
            requiresAuth: true,
            requiresAdmin: true,
            accessLevel: [1, 3, 8],
          },
        },
        {
          path: "/registrations",
          name: "Logs (Registrations)",
          component: () =>
            import(
              /* webpackChunkName: "registrations" */
              "../views/registrations.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresAdmin: true,
            accessLevel: [1, 3, 8],
          },
        },
        {
          path: "/reports",
          name: "Reports",
          component: () =>
            import(/* webpackChunkName: "reports" */ "../views/reports.vue"),
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            accessLevel: [1, 3, 5, 8],
          },
        },
        {
          path: "/swaps",
          name: "Swap",
          component: () =>
            import(/* webpackChunkName: "swaps" */ "../views/swaps.vue"),
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            accessLevel: [1, 3, 5, 8],
          },
        },
        {
          path: "/generateReports",
          name: "Generate Reports",
          component: () =>
            import(
              /* webpackChunkName: "generateReports" */
              "../views/generateReports.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            accessLevel: [1, 3, 5, 8],
          },
        },
        {
          path: "/uploadReports",
          name: "Upload Reports",
          component: () =>
            import(
              /* webpackChunkName: "uploadReports" */
              "../views/uploadReports.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresAdmin: false,
            accessLevel: [1, 3, 5, 8],
          },
        },
        {
          path: "/signature",
          name: "Signature",
          component: () =>
            import(
              /* webpackChunkName: "signature" */
              "../views/signature.vue"
            ),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/password-policy",
          name: "Password Policy",
          component: () =>
            import(
              /* webpackChunkName: "passwordPolicy" */
              "../views/passwordPolicy"
            ),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/vpn",
          name: "VPN Configuration",
          component: () =>
            import(/* webpackChunkName: "VPN" */ "../views/vpn.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/profile",
          name: "Account",
          component: () =>
            import(/* webpackChunkName: "profile" */ "../views/profile.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/agenda",
          name: "Agenda",
          component: () =>
            import(/* webpackChunkName: "users" */ "../views/agenda.vue"),
          meta: {
            requiresAuth: true,
            requiresAdmin: true,
          },
        },
        {
          path: "/blog",
          name: "Blog",
          component: () =>
            import(/* webpackChunkName: "blog" */ "../views/blog.vue"),
          meta: {
            requiresAuth: true,
            accessLevel: [1, 6, 7, 8],
          },
        },
        {
          path: "/maintenance",
          name: "Maintenance",
          component: () =>
            import(
              /* webpackChunkName: "maintenance" */
              "../views/maintenance.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresAdmin: true,
            accessLevel: [1],
          },
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: () =>
        import(/* webpackChunkName: "login" */ "../views/login.vue"),
      beforeEnter: (to, from, next) => {
        const token = localStorage.getItem("token");
        if (token) {
          next({
            path: "/",
          });
        } else {
          next();
        }
      },
    },
    {
      path: "*",
      redirect: {
        name: "Dashboard",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const accessLevel = to.meta.accessLevel;
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("isAdmin");
  const isManager = isAdmin === "true" || role <= 2;
  const department = parseInt(localStorage.getItem("accessLevel"));

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

  if (requiresAuth) {
    if (!token) {
      return next({
        path: "/login",
      });
    } else {
      if (requiresAdmin) {
        if (isManager) {
          next();
        } else {
          next("/");
        }
      } else {
        if (accessLevel) {
          if (accessLevel.includes(department)) {
            next();
          } else {
            next("/");
          }
        }
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
