<template>
  <div id="app">
    <toast></toast>
    <router-view></router-view>
  </div>
</template>

<script>
import toast from "./components/toast";
export default {
  name: "App",
  components: {
    toast,
  },
  created() {
    document.title = "TrioMarkets | Hub";
  },
};
</script>