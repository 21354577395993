import { getters } from "../store";

const checkDepartment = (departments) => {
  let accessList = [];
  getters.departments().map((department) => {
    if (departments.includes(department.name)) {
      accessList.push(department.id);
    }
  });
  return accessList;
  // return departments.map((department) => {
  //   return getters.departments().find(({ name }) => {
  //     return name === department;
  //   }).id;
  // });
};

const checkPermissions = (departments = [], isManager = false) => {
  const accessLevel = parseInt(localStorage.getItem("accessLevel"));
  const permissions = checkDepartment(departments);
  const hasPermissions = permissions.includes(accessLevel);
  if (isManager) {
    if (departments.length > 0) {
      return hasPermissions;
    } else {
      return getters.hasPermissions();
    }
  } else {
    return hasPermissions;
  }
};

export { checkPermissions };
