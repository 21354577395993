import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import {
  email,
  image,
  mimes,
  numeric,
  required,
  confirmed,
} from "vee-validate/dist/rules";

localize("en", en);
extend("email", email);
extend("image", image);
extend("mimes", mimes);
extend("numeric", numeric);
extend("required", required);
extend("confirmed", confirmed);
extend("dropdown", (value) => {
  if (value >= 0) {
    return true;
  }
  return "The {_field_} field is required";
});
extend("image", (value) => {
  if (value) {
    if (typeof value === "string") {
      if (value.startsWith("data:")) {
        return true;
      }
    } else {
      if (value[0].type.match("image.*")) {
        return true;
      }
    }
    return "The {_field_} field must be image";
  }
  return "The {_field_} field is required";
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
