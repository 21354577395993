import Vue from "vue";
import axios from "axios";
import router from "../routes";
import { mutations } from "../store";

const base = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/hub"
      : "https://api.triomarkets.com/hub",
});

base.interceptors.request.use(
  (config) => {
    let accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        ...config.headers,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

base.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      mutations.showToast({
        success: false,
        message: "Network Error",
      });
      mutations.logoutUser();
    } else {
      switch (error.response.status) {
        case 401:
          router.push("/home");
          break;
        case 403:
          mutations.logoutUser();
          error.response.data.message = "Session has been expired!";
          break;
        case 404:
          error.response.data.message = "Something went wrong!";
          break;
        default:
          break;
      }
      mutations.showToast(error.response.data);
      let modal = document.getElementsByClassName("modal-backdrop")[0];
      if (modal) {
        modal.remove();
      }
    }
    return error;
  }
);

Vue.prototype.$axios = base;
